import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const BuildingConstruction = ({ data }) => {
  return (
    <>
      <Seo
        title="Building and Construction Industries, North Central Pennsylvania"
        description="The Building and Construction (BC) cluster includes industries directly involved in the construction of housing, roadways or other physical structures."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="10%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Building and Construction</h2>
            <p>
              The Building and Construction (BC) cluster includes industries
              directly involved in the construction of housing, roadways or
              other physical structures.
            </p>
          </div>

          <div className="intro-text">
            <p>
              Industries such as HVAC equipment manufacturing, whose products
              are used exclusively in the construction of these structures, are
              also included.
            </p>

            <p>
              The retail industries such as Home Centers which act as a middle-
              man between manufacturer and independent contractors are included
              as well. Lastly, technical firms offering drafting or
              architectural services are also in the cluster.
            </p>
          </div>
        </section>

        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="Olmsted Manor, McKean County"
        />
      </main>

      <PathwaysIntro
        industry="Building and Construction"
        image={data.pathwaysImage.sharp.image}
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: building {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }

    careerImage: file(relativePath: { eq: "2020-04-07-IMG_1413-s2065.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(
      relativePath: { eq: "william-wendling-PVGYTu5yAAA-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(
      relativePath: { eq: "robert-svebeck-TNsLQrmTo2Y-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "robert-svebeck-TNsLQrmTo2Y-unsplash.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;

export default BuildingConstruction;
